@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #dde2e8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #111827;
}

main {
  min-height: calc(100vh);
}

.custom-drawer {
  .ant-drawer-header-title {
    flex: none;
  }
}

.ant-image-mask
{
  display: none !important;
}

.customImgaeGrid {
  width: 100%;
  height: 100%;
  .ant-image{
    width: 100%;
    height: 100%;
  }
}